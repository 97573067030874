import { isAfter } from 'date-fns'
import React, { ReactElement, useMemo } from 'react'

import { Sorting } from '@enums'
import { ApiError } from '@lib/api'
import dateUtils from '@lib/date'
import sort from '@lib/sorting'
import BookedOut from '@pages/SearchResult/WeeklyList/Item/BookedOut'
import ConnectionError from '@pages/SearchResult/WeeklyList/Item/Error'
import TimeSkeleton from '@pages/SearchResult/WeeklyList/Skeleton'
import { Dropdown, Icon, Skeleton } from '@ui'
import { DropdownItem } from '@ui/Dropdown'

interface ConnectionCellProps {
  isLoading: boolean
  error: ApiError | null
  connections?: Connection[]
  target?: Connection | null
  value: Connection | null
  onChange: (selected: Connection | null) => void
}

const PLACEHOLDER = '--:--'

const getTime = (date: string): string => dateUtils.formatTime(dateUtils.parse(date, 'UTC'))

const ConnectionCell = (props: ConnectionCellProps): ReactElement => {
  const { value, onChange, isLoading, error, target, connections = [] } = props

  const items = useMemo(() => {
    let list: Connection[] = connections

    if (target)
      list = connections.filter(({ departureTime }) => isAfter(new Date(departureTime), new Date(target.arrivalTime)))

    return sort.sortConnectionList(list, Sorting.DepartureTime).reduce<DropdownItem<string>[]>((acc, curr) => {
      const date = getTime(curr.departureTime)

      return curr.bookedOut ? acc : [...acc, { label: date, value: curr.id }]
    }, [])
  }, [connections, target])

  const handleChange = (id: string): void => {
    onChange(connections?.find(connection => connection.id === id) ?? /* istanbul ignore next */ null)
  }

  const isEmpty = items.length === 0 && !isLoading && !error
  const showDropdown = (!error && items.length > 0) || isLoading

  return (
    <>
      {showDropdown && (
        <Skeleton.List loading={isLoading} amount={1} Skeleton={TimeSkeleton}>
          <Dropdown
            items={items}
            value={value?.id ?? null}
            label={value?.id ? null : PLACEHOLDER}
            onChange={handleChange}
            popperProps={{ className: 'weekly-list__popper' }}
            modalClassName="weekly-list__time-modal"
          />
        </Skeleton.List>
      )}
      {value && <Icon name="cross" size="medium" className="ml-3 ml-lg-1" onClick={() => onChange(null)} />}
      {error && <ConnectionError error={error} />}
      {isEmpty && <BookedOut />}
    </>
  )
}

export default ConnectionCell
